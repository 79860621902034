<template>
  <div>
    <div class="container-fluid pt-3 pb-5">
      <div class="row bg-white" style="border-style: double; border-color: #B5B5C3;">
        <div class="col-md-12">
          <div class="row pt-3 pb-3" style="background-color: #CCFFFF;">
            <div class="col-md-10">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link text-dark active" id="list-tab" data-toggle="tab" href="#list">
                    <span class="nav-text">集团管控</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md text-right"><img src="media/logos/logo.png" style="width: 10rem;" /></div>
          </div>
          <div class="tab-content mt-3 border" id="myTabContent">
            <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
              <addOrderDeail></addOrderDeail>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import addOrderDeail from "@/view/content/groupCenter/deail/addOrderDeail.vue";
export default {
  components: {
    addOrderDeail
  }
};
</script>
