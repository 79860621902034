<template>
  <div>
    <div class="row align-items-center border-bottom" v-if="!(useAgeData && useAgeData == 3)">
      <div class="col-md-6"><h5>材料查看</h5></div>
      <div class="col-md text-right">
        <button class="btn btn-outline-primary btn-sm" v-on:click="cicleModal">材料周期</button>&nbsp;
        <button class="btn btn-outline-primary btn-sm" v-on:click="areaPriceModal">平米单价</button>&nbsp;
        <button class="btn btn-outline-primary btn-sm" v-on:click="useAreaModal">报备面积</button>
      </div>
    </div>
    <div class="row align-items-center border-bottom">
      <div class="col-md-4">
        <span class="font-size-lg">加工费</span>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{ excute.totomlMoney?excute.totomlMoney:0 }}元</span>
      </div>
    </div>
    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">材料费用</span>&nbsp;
        <span style="font-size: 1px;" id="materialFee"><i :class="iconClass1" v-on:mouseenter="onEnterId(1)" v-on:mouseleave="onLeaveId(1)"></i></span>
        <b-popover target="materialFee" triggers="hover focus" placement="bottom">
          <div class="row pt-2">
            <div class="col-md">
              材料金额 = 材料平米单价 * (订单平米 / 利用率)({{materialMsg}})
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{materialFee}}元 </span>
      </div>
    </div>
<!--    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">指定物料材料费用</span>&nbsp;
        <span style="font-size: 1px;" id="materialFeeTwo"><i :class="iconClass1" v-on:mouseenter="onEnterId(10)" v-on:mouseleave="onLeaveId(10)"></i></span>
        <b-popover target="materialFeeTwo" triggers="hover focus" placement="bottom">
          <div class="row pt-2">
            <div class="col-md">
              材料金额 = 材料平米单价 * (订单平米 / 利用率)({{materialMsgTwo}})
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{appointMaterialFee}}元 </span>
      </div>
    </div>-->
    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">采购费</span>&nbsp;
        <span style="font-size: 1px;" id="prdAmount"><i :class="iconClass10" v-on:mouseenter="onEnterId(10)" v-on:mouseleave="onLeaveId(10)"></i></span>
        <b-popover target="prdAmount" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              采购费 = {{ notification.prdMouldFee ? '采购模具费用' + notification.prdMouldFee + ' + ' : '' }}
              {{ notification.prdTestShelfFee ? '采购测试架费' + notification.prdTestShelfFee + ' + ' : '' }}
              {{ notification.prdEngineeringFee ? '采购工程费用' + notification.prdEngineeringFee + ' + ' : '' }}
              {{ notification.prdFilmFee ? '采购菲林费用' + notification.prdFilmFee + ' + ' : '' }}
              {{ notification.prdOthersFee ? '采购其它费用' + notification.prdOthersFee : '' }}
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{notification.prdAmount}}元</span>
      </div>
    </div>
    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">业务费</span>&nbsp;
        <span style="font-size: 1px;" id="saleFee"><i :class="iconClass3" v-on:mouseenter="onEnterId(3)" v-on:mouseleave="onLeaveId(3)"></i></span>
        <b-popover target="saleFee" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              业务费 = 销售金额{{notification.conDeail.computAmount}} * 业务费费率{{salePrecent}}%
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{notification.saleFee}}元</span>
      </div>
    </div>
    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">管理费</span>&nbsp;
        <span style="font-size: 1px;" id="manageFee"><i :class="iconClass4" v-on:mouseenter="onEnterId(4)" v-on:mouseleave="onLeaveId(4)"></i></span>
        <b-popover target="manageFee" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              管理费 = 销售金额{{notification.conDeail.computAmount}} * 管理费费率{{managePrecent}}%
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{notification.manageFee}}元</span>
      </div>
    </div>
    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">管销费</span>&nbsp;
        <span style="font-size: 1px;" id="tubePinFee"><i :class="iconClass12" v-on:mouseenter="onEnterId(12)" v-on:mouseleave="onLeaveId(12)"></i></span>
        <b-popover target="tubePinFee" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              管销费 = 销售金额{{notification.conDeail.computAmount}} * 0.8%
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{tubePinFee}}元</span>
      </div>
    </div>
    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">毛利率</span>&nbsp;
        <span style="font-size: 1px;" id="grossProfitRate"><i :class="iconClass5" v-on:mouseenter="onEnterId(5)" v-on:mouseleave="onLeaveId(5)"></i></span>
        <b-popover target="grossProfitRate" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              毛利率 = (销售金额{{notification.conDeail.computAmount}} - 成本费用{{notification.productFeeOne}})/ 销售金额{{notification.conDeail.computAmount}}
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">{{notification.grossProfitRate}}%</span>
      </div>
    </div>
    <div class="row align-items-center border-bottom">
      <div class="col-md"><span class="font-size-lg">净利率</span>&nbsp;
        <span style="font-size: 1px;" id="netProfitRate"><i :class="iconClass6" v-on:mouseenter="onEnterId(6)" v-on:mouseleave="onLeaveId(6)"></i></span>
        <b-popover target="netProfitRate" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              净利率 = (销售金额{{notification.conDeail.computAmount}} -
              成本费用{{notification.productFeeOne}} -
              业务费{{notification.saleFee}} -
              管理费{{notification.manageFee}} -
              管销费{{tubePinFee}})
              /销售金额{{notification.conDeail.subTotal?notification.conDeail.subTotal:0}}
            </div>
          </div>
        </b-popover>
      </div>
      <div class="col-md text-right" style="font-size: 0.1rem;">
        <span class="font-weight-bold" style="font-size: 1.6rem">{{notification.netProfitRate}}%</span>
      </div>
    </div>
    <div class="row align-items-center pt-1 pb-1">
      <div class="col-md"><span class="font-size-lg">成本费用</span>&nbsp;
        <span style="font-size: 1px;" id="productFee"><i :class="iconClass7" v-on:mouseenter="onEnterId(7)" v-on:mouseleave="onLeaveId(7)"></i></span>
        <b-popover target="productFee" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              成本费用 = 加工费{{excute.totomlMoney}} + 材料费用{{notification.materialFee}} + 采购费{{notification.prdAmount}}
            </div>
          </div>
        </b-popover>
      </div>
    </div>
    <div class="row border-bottom pb-1">
      <div class="col-md border-secondary">单价<span style="font-size: 1px;" id="price"><i :class="iconClass11" v-on:mouseenter="onEnterId(11)" v-on:mouseleave="onLeaveId(11)"></i></span>:￥{{notification.productPriceOne}}元/PCS</div>
      <div class="col-md text-right">金额:￥{{notification.productFeeOne}}元</div>
      <b-popover target="price" triggers="hover focus" placement="bottom">
        <div class="row">
          <div class="col-md">
            单价 = (加工费{{excute.totomlMoney}} + 材料费用{{notification.materialFee}}) / 订单数量{{notification.conDeail.quantity}}
          </div>
        </div>
      </b-popover>
    </div>
    <div class="row pt-1 pb-1">
      <div class="col-md"><span class="font-size-lg">总部经济-龙南</span>&nbsp;
        <span style="font-size: 1px;" id="economyFee"><i :class="iconClass8" v-on:mouseenter="onEnterId(8)" v-on:mouseleave="onLeaveId(8)"></i></span>
        <b-popover target="economyFee" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              总部经济费用 = 成本费用{{notification.productFeeOne}} * 总部经济比例{{materialResult.lnPrecent}}%
            </div>
          </div>
        </b-popover>
      </div>
    </div>
    <div class="row border-bottom pb-1">
      <div class="col-md border-secondary">单价:({{notification.exchangeType?notification.exchangeType:"RMB"}}){{notification.economyFeePrice}}<span v-if="notification.exchangeType">-(RMB){{notification.exEconomyFeePrice}}</span>/PCS</div>
      <div class="col-md text-right">金额:({{notification.exchangeType?notification.exchangeType:"RMB"}}){{notification.economyFee}}<span v-if="notification.exchangeType">-(RMB){{notification.exEconomyFee}}</span></div>
    </div>
    <div class="row pt-1 pb-1">
      <div class="col-md"><span class="font-size-lg">生产工厂-江西</span>&nbsp;
        <span style="font-size: 1px;" id="factoryFee"><i :class="iconClass9" v-on:mouseenter="onEnterId(9)" v-on:mouseleave="onLeaveId(9)"></i></span>
        <b-popover target="factoryFee" triggers="hover focus" placement="bottom">
          <div class="row">
            <div class="col-md">
              生产工厂费用 = 成本费用{{notification.productFeeOne}} * 生产工厂比例{{materialResult.jxPrecent}}%
            </div>
          </div>
        </b-popover>
      </div>
    </div>
    <div class="row border-bottom pb-1">
      <div class="col-md border-secondary">单价:￥{{notification.factoryFeePrice}}元/PCS</div>
      <div class="col-md text-right">金额:￥{{notification.factoryFee}}元</div>
    </div>
    <div class="modal fade" id="cicleModal" tabindex="-1" role="dialog" aria-labelledby="cicleModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row border-bottom pt-3 pb-3">
              <div class="col-md text-center">品牌</div>
              <div class="col-md text-center">具体料</div>
              <div class="col-md text-center">规格</div>
              <div class="col-md text-center">采购周期</div>
            </div>
            <div class="row align-items-center border-bottom pt-3 pb-3" v-for="(item,index) in orgList" :key="index">
              <div class="col-md text-center">
                {{item.manufacturer}}
              </div>
              <div class="col-md text-center">
                {{item.no}}
              </div>
              <div class="col-md text-center">
                {{item.name}} {{item.specification}}
              </div>
              <div class="col-md text-center">
                {{item.leadTime}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="areaPriceModal" tabindex="-1" role="dialog" aria-labelledby="areaPriceModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-xl" style="max-width:1800px" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row border-bottom pt-3 pb-3">
              <div class="col-md text-center">品牌</div>
              <div class="col-md text-center">具体料</div>
              <div class="col-md text-center">规格</div>
              <div class="col-md text-center">基础价格</div>
              <div class="col-md text-center">月结方式</div>
              <div class="col-md text-center" widh="30%">计算方式</div>
              <div class="col-md text-center">最新采购价/平米价</div>
              <div class="col-md text-center">平均平米价</div>
            </div>
            <div class="row align-items-center border-bottom pt-3 pb-3" v-for="(item,index) in materialList" :key="index" v-bind:class="{ 'bg-primary': item.avgPrice && item.avgPrice > 0}">
              <div class="col-md text-center">
                {{item.manufacturer}}
              </div>
              <div class="col-md text-center">
                {{item.no}}
              </div>
              <div class="col-md text-center">
                {{item.name}} {{item.specification}}
              </div>
              <div class="col-md text-center">
                {{item.oldPrice}}
              </div>
              <div class="col-md text-center">
                {{item.payWay}}
              </div>
              <div class="col-md text-center" widh="30%">
                {{item.priceCpmputRsult}}
              </div>
              <div class="col-md text-center">
                {{item.price}}/{{item.areaPrice}}
              </div>
              <div class="col-md text-success text-center">
                {{item.avgPrice}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="useAreaModal" tabindex="-1" role="dialog" aria-labelledby="useAreaModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
          <div class="row pt-3 pl-3">
            <div class="col-md">
              订单客户：{{notification.customerName}}
            </div>
          </div>
          <div class="modal-body">
            <div class="row border-bottom pt-3 pb-3">
              <div class="col-md text-center">指定客户</div>
              <div class="col-md text-center">规格</div>
              <div class="col-md text-center">备料面积</div>
              <div class="col-md text-center">已用面积</div>
              <div class="col-md text-center">可用面积</div>
              <div class="col-md text-center">到货日期</div>
            </div>
            <div class="row align-items-center border-bottom pt-3 pb-3" v-for="(item,index) in materials" :key="index">
              <div class="col-md text-center">
                {{item.customerName}}
              </div>
              <div class="col-md text-center">
                {{item.specification}}
              </div>
              <div class="col-md text-center">
                {{item.sumArea}}
              </div>
              <div class="col-md text-center">
                {{item.useArea}}<span v-if="item.customerId && notification.customerId != item.customerId" class="text-danger">/不可用</span>
              </div>
              <div class="col-md text-success text-center">
                {{item.canUseArea}}
              </div>
              <div class="col-md text-center">
                {{item.arrivalDateStr}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["setUpPrice", "excute", "notification", "salePrecent", "managePrecent", "materialResult", "materialList", "materials", "materialFee","appointMaterialFee","materialMsgTwo", "materialMsg", "orgList","useAgeData","tubePinFee"],
  data() {
    return {
      iconClass1: "fa fa-question-circle alert-link",
      iconClass2: "fa fa-question-circle alert-link",
      iconClass3: "fa fa-question-circle alert-link",
      iconClass4: "fa fa-question-circle alert-link",
      iconClass5: "fa fa-question-circle alert-link",
      iconClass6: "fa fa-question-circle alert-link",
      iconClass7: "fa fa-question-circle alert-link",
      iconClass8: "fa fa-question-circle alert-link",
      iconClass9: "fa fa-question-circle alert-link",
      iconClass10: "fa fa-question-circle alert-link",
      iconClass11: "fa fa-question-circle alert-link",
      iconClass12: "fa fa-question-circle alert-link"
    };
  },
  methods: {
    areaPriceModal: function (){
      $("#areaPriceModal").modal();
    },
    cicleModal: function (){
      $("#cicleModal").modal();
    },
    useAreaModal: function (){
      $("#useAreaModal").modal();
    },
    onEnterId(flag) {
      if (flag == 1){
        this.iconClass1 = "fa fa-question-circle alert-success";
      }else if (flag == 2){
        this.iconClass2 = "fa fa-question-circle alert-success";
      }else if (flag == 3){
        this.iconClass3 = "fa fa-question-circle alert-success";
      }else if (flag == 4){
        this.iconClass4 = "fa fa-question-circle alert-success";
      }else if (flag == 5){
        this.iconClass5 = "fa fa-question-circle alert-success";
      }else if (flag == 6){
        this.iconClass6 = "fa fa-question-circle alert-success";
      }else if (flag == 7){
        this.iconClass7 = "fa fa-question-circle alert-success";
      }else if (flag == 8){
        this.iconClass8 = "fa fa-question-circle alert-success";
      }else if (flag == 9){
        this.iconClass9 = "fa fa-question-circle alert-success";
      }else if (flag == 10){
        this.iconClass10 = "fa fa-question-circle alert-success";
      }else if (flag == 11){
        this.iconClass11 = "fa fa-question-circle alert-success";
      }else if (flag == 12){
        this.iconClass12 = "fa fa-question-circle alert-success";
      }
    },
    onLeaveId(flag) {
      if (flag == 1){
        this.iconClass1 = "fa fa-question-circle alert-link";
      }else if (flag == 2){
        this.iconClass2 = "fa fa-question-circle alert-link";
      }else if (flag == 3){
        this.iconClass3 = "fa fa-question-circle alert-link";
      }else if (flag == 4){
        this.iconClass4 = "fa fa-question-circle alert-link";
      }else if (flag == 5){
        this.iconClass5 = "fa fa-question-circle alert-link";
      }else if (flag == 6){
        this.iconClass6 = "fa fa-question-circle alert-link";
      }else if (flag == 7){
        this.iconClass7 = "fa fa-question-circle alert-link";
      }else if (flag == 8){
        this.iconClass8 = "fa fa-question-circle alert-link";
      }else if (flag == 9){
        this.iconClass9 = "fa fa-question-circle alert-link";
      }else if (flag == 10){
        this.iconClass10 = "fa fa-question-circle alert-link";
      }else if (flag == 11){
        this.iconClass11 = "fa fa-question-circle alert-link";
      }else if (flag == 12){
        this.iconClass12 = "fa fa-question-circle alert-link";
      }
    }
  }
};
</script>
