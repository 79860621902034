<template>
  <div>
    <div>
      <div class="border row">
        <div class="col-md-12">
          <div class="row p-1">
            <span class="text-success font-weight-bold">用料利用率：{{materialResult.useRatio}}%</span>
          </div>
        </div>
        <div class="col-md-12" v-if="materialResult && materialResult.oldTotalPcses">
          <div class="row p-1" v-if="materialResult.oldTotalPcses">
            <span class="font-weight-bold">参考工程资料：</span>
            <span>每张大料可开:{{ materialResult.totalSets }}SET/{{materialResult.oldTotalPcses }}PCS</span>
            <span>利用率:{{ materialResult.oldUseRatio }}%</span>s
          </div>
          <div class="row p-1" v-if="materialResult.setLength && materialResult.setWidth && materialResult.pnlDivisor">
            <span class="font-weight-bold">工程尺寸：</span>
            <span>{{ materialResult.setLength}} * {{ materialResult.setWidth}} / {{ materialResult.pnlDivisor}}</span>
          </div>
          <div class="row p-1">
            <div>
              <span>大板开料图</span>
            </div>
            <div v-if="materialResult.path != null && materialResult.path != ''">
              <img :src="materialResult.path" class="w-100" />
            </div>
            <div v-else>
              :无
            </div>
          </div>
          <div class="row p-1">
            <div class="col-md-6">
              <div class="row p-1">
                <div>
                  <span>A板开料图</span>
                </div>
                <div v-if="materialResult.pathA != null &&materialResult.pathA != ''">
                  <img :src="materialResult.pathA" class="w-100" />
                </div>
                <div v-else>
                  :无
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row p-1">
                <div>
                  <span>B板开料图</span>
                </div>
                <div v-if="materialResult.pathB != null && materialResult.pathB != ''">
                  <img :src="materialResult.pathB" class="w-100"/>
                </div>
                <div v-else>
                  :无
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["materialResult","canEditFlag"],
  methods:{
    getQueryVariable:function(){
      this.$emit("countMaterialMoney", null);
    }
  }
};
</script>
