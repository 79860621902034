<template>
  <div>
    <div class="pt-3 border-top">
      <div class="row align-items-center">
        <div class="col-md"><span class="text-primary">交期评估结果</span>(最大批次面积{{maxBatchArea}}㎡)</div>
        <div class="col-md text-right">
          <a data-toggle="modal" data-target="#cycleCountTable" style="font-size: 0.1rem;cursor: pointer" class="text-primary pl-5">周期规则</a>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md">
          <div v-if="canEditFlag">
            <div class="pb-3">
              <div>
                <div class="row" v-if="productionCycleId == 2">
                  <div class="col-md-12">
                    <span v-if="starvingNum > 0 && groupCenterLine.materialUse == 1" class="text-danger font-weight-bolder">异常：材料缺{{ starvingNum }}平米，请换一种加急方式</span>
                    <span v-else-if="starvingNum > 0" class="text-danger font-weight-bolder">异常：材料缺{{ starvingNum }}平米</span>
                    <span v-else-if="starvingNum < 0" class="text-warning">材料超过{{ -starvingNum }}平米</span>
                  </div>
                </div>
                <div class="row" v-if="productionCycleId == 2 && !materialResult.useRatio">
                  <div class="col-md-12">
                    <span class="font-weight-bolder text-danger">异常：未设置默认利用率，请联系生产工厂设置</span>
                  </div>
                </div>
<!--                <div class="row" v-if="productionCycleId == 2 && notification.conDeail.dictOrderType == 2  && urgentApproval != 1">
                  <div class="col-md-12">
                    <span class="text-danger font-weight-bolder">异常：生产工厂没有分配给该下单部门或者客户的量产款数</span>
                  </div>
                </div>-->
<!--                <div class="row" v-if="notification.conDeail.dictOrderType == 1 && !(Number(materialResult.sampleOrderCount) > 0)">
                  <div class="col-md-12">
                    <span class="text-danger font-weight-bolder">异常：生产工厂没有分配给该下单部门或者客户的样品款数</span>
                  </div>
                </div>-->
<!--                <div class="row" v-if="(notification.conDeail.dictOrderType == 1 || productionCycleId == 2) &&
                notification.conDeail.dictCardType == 1 && !(Number(materialResult.newOrderCount) > 0)">
                  <div class="col-md-12">
                    <span class="text-danger font-weight-bolder">异常：生产工厂没有分配给该下单部门或者客户的新单款数</span>
                  </div>
                </div>-->
                <div class="row" v-if="notification.conDeail.dictOrderType == 2 && !(Number(materialResult.capacity) > 0) && urgentApproval != 1">
                  <div class="col-md-12">
                    <span class="text-danger font-weight-bolder">异常：生产工厂没有分配给该下单部门或者客户的量产产能</span>
                  </div>
                </div>
                <div class="row" v-if="!(Number(maxBatchArea) > 0)">
                  <div class="col-md-12">
                    <span class="text-danger font-weight-bolder">异常：生产工厂限制批次最大产能错误，请让工厂进行参数设置</span>
                  </div>
                </div>
                <div class="row" v-if="!materialResult.needDays && notification.conDeail.dictOrderType">
                  <div class="col-md-12">
                    <span class="text-danger font-weight-bolder">异常：匹配不到生产周期</span>
                  </div>
                </div>
                <div class="row" v-if="estimateMethod && !estimateDate">
                  <div class="col-md-12">
                    <span class="text-danger font-weight-bolder">异常：{{estimateMethod}}</span>
                  </div>
                </div>
              </div>
              <div v-if="estimateMethod && estimateDate">
                <div class="row">
                  <div class="col-md-12">
                    <span class="font-weight-bolder" :class="productionCycleId == '3' ||productionCycleId == '4'?'text-danger':'text-success'">
                      {{estimateRemark}}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <span class="font-weight-bolder">评估交期<span class="text-primary">（{{estimateDate}}）</span> = {{ estimateMethod }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="font-weight-bolder">客户交期：
                      <span :class="productionCycleId == '3'?'text-danger':'text-success'">{{notification.conDeail.deliveryDateStr}}</span></div>
                  </div>
                </div>
                <div class="row" v-if="acceptFlag == 1">
                  <div class="col-md-12">
                    <div class="font-weight-bolder text-success">不能按客户预期时间交付，但已接受该生产周期</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else-if="groupCenterLine && groupCenterLine.recordId">
            <div class="col-md-12">
              <div>
                <div>评估日期:{{ groupCenterLine.estimateStartDate }}</div>
                <div>预估出货:{{ groupCenterLine.estimateDateStr }}</div>
                <div>计算方法:{{ groupCenterLine.estimateMethod }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="cycleCountTable" tabindex="-1" role="dialog" aria-labelledby="moneyCountTable" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row" v-if="materialResult && materialResult.versionDate">
              <div class="col-md">
                <div class="card card-custom gutter-b">
                  <div class="card-body">
                    <div class="row pt-1 pb-1 border-bottom border-dark"><div class="col-md text-left">周期版本号-{{materialResult.versionDate}}（<span class="text-primary">
                  蓝色为配对规则</span>）</div></div>
                    <div class="row pt-1 pl-3 pr-3" style="font-size: 0.8rem;" v-if="canEditFlag">
                      <template v-for="(row, recordId) in materialResult.cycleSetupList">
                        <div class="col-md-12" :key="recordId">
                          <div class="row align-items-center" style="font-size: 0.5rem;">
                            <div class="col-md-12 p-1">
                              <span :class="row.useFlag?'text-primary':'text-muted'">{{row.name}}: 时间{{row.nomalDays}}天</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="row pt-1 pl-3 pr-3" style="font-size: 0.8rem;" v-else>
                      <template v-for="(row, recordId) in oldSetupList">
                        <div class="col-md-12" :key="recordId">
                          <div class="row align-items-center" style="font-size: 0.5rem;">
                            <div class="col-md-12 p-1">
                              <span :class="row.useFlag?'text-primary':'text-muted'">{{row.name}}: 评估时间{{row.nomalDays}}天</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["canEditFlag", "notification", "groupCenterLine", "materialResult", "starvingNum", "estimateMethod", "estimateDate", "estimateRemark", "productionCycleId", "acceptFlag",
    "oldSetupList", "maxBatchArea","urgentApproval"]
};
</script>
