<template>
  <div v-if="conDeail">
    <div class="border row">
      <div class="col-md">
        <div class="row p-1">
          <div class="col-md-5">
            <div class="row align-items-center">
              PCS尺寸:
              <div class="col-md">
                <input type="text" class="form-control" placeholder="长" v-model="conDeail.unitLength" disabled/>
              </div>
              <span>*</span>
              <div class="col-md">
                <input type="text" class="form-control" placeholder="宽" v-model="conDeail.unitWidth" disabled/>
              </div>(mm)&emsp;
            </div>
          </div>
          <div class="col-md-7">
            <div class="row align-items-center">
              交货尺寸:
              <div class="col-md">
                <input type="text" class="form-control" placeholder="长" v-model="conDeail.pnlLength" disabled/>
              </div>
              <span>*</span>
              <div class="col-md">
                <input type="text" class="form-control" placeholder="宽" v-model="conDeail.pnlWidth" disabled/>
              </div>
              <span>/</span>
              <div class="col-md">
                <input type="text" class="form-control" placeholder="拼接数" v-model="conDeail.pnlDivisor" disabled/>
              </div>(mm)
            </div>
          </div>
          <div class="col-md-12 pt-1">
            <div class="row align-items-center">
              客户型号:
              <div class="col-md">
                <input type="text" class="form-control" placeholder="请填写客户型号" v-model="conDeail.customerModel" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.boardLevelList &&craft.boardLevelList.length > 0 &&conDeail.boardLevel">
            <div class="row align-items-center">
              PCB类型:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.boardLevel" disabled>
                  <option v-for="item in craft.boardLevelList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.materialTypeList &&craft.materialTypeList.length > 0 &&conDeail.materialType">
            <div class="row align-items-center">
              覆铜板材:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.materialType" disabled>
                  <option v-for="item in craft.materialTypeList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.boardThicknessList &&craft.boardThicknessList.length > 0 &&conDeail.boardThickness">
            <div class="row align-items-center">
              板材厚度:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.boardThickness" disabled>
                  <option v-for="item in craft.boardThicknessList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.copperCladThicknessList &&craft.copperCladThicknessList.length > 0 &&conDeail.copperCladThickness">
            <div class="row align-items-center">
              覆铜要求:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.copperCladThickness" disabled>
                  <option v-for="item in craft.copperCladThicknessList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.surfaceProcessList &&craft.surfaceProcessList.length > 0 &&conDeail.surfaceProcess">
            <div class="row align-items-center">
              镀层处理:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.surfaceProcess" disabled>
                  <option v-for="item in craft.surfaceProcessList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.solderMaskTypeList &&craft.solderMaskTypeList.length > 0 &&conDeail.solderMaskType">
            <div class="row align-items-center">
              阻焊类型:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.solderMaskType" disabled>
                  <option v-for="item in craft.solderMaskTypeList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.characterTypeList &&craft.characterTypeList.length > 0 &&conDeail.characterType">
            <div class="row align-items-center">
              板面字符:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.characterType" disabled>
                  <option v-for="item in craft.characterTypeList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.shapingWayList &&craft.shapingWayList.length > 0 &&conDeail.shapingWay">
            <div class="row align-items-center">
              成型方式:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.shapingWay" disabled>
                  <option v-for="item in craft.shapingWayList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.testMethodList &&craft.testMethodList.length > 0 &&conDeail.testMethod">
            <div class="row align-items-center">
              测试要求:
              <div class="col-md">
                <select class="form-control" v-model="conDeail.testMethod" disabled>
                  <option v-for="item in craft.testMethodList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1">
            <div class="row align-items-center">
              曝光/丝印:
              <div class="col-md">
                <input class="form-control" value="曝光" disabled v-if="conDeail.processValueId && conDeail.processValueId === '1'"/>
                <input class="form-control" value="丝印" disabled v-else-if="conDeail.processValueId && conDeail.processValueId === '2'">
                <input class="form-control" value="无" disabled v-else/>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.daoreList &&craft.daoreList.length > 0 &&conDeail.daore">
            <div class="row align-items-center">
              <span>导&emsp;&emsp;热:</span>
              <div class="col-md">
                <select class="form-control" v-model="conDeail.daore" disabled>
                  <option v-for="item in craft.daoreList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.naiyaList &&craft.naiyaList.length > 0 &&conDeail.naiya">
            <div class="row align-items-center">
              <span>耐&emsp;&emsp;压:</span>
              <div class="col-md">
                <select class="form-control" v-model="conDeail.naiya" disabled>
                  <option v-for="item in craft.naiyaList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.productionTypeList &&craft.productionTypeList.length > 0 &&conDeail.referenceType">
            <div class="row align-items-center">
              订单类型:
              <div class="col-md">
                <select class="form-control text-danger" v-model="conDeail.referenceType" disabled>
                  <option v-for="item in craft.productionTypeList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.lingeSpacingList &&craft.lingeSpacingList.length > 0 &&conDeail.lingeSpacing">
            <div class="row align-items-center">
              <span>线宽线距:</span>
              <div class="col-md">
                <select class="form-control" v-model="conDeail.lingeSpacing" disabled>
                  <option v-for="item in craft.lingeSpacingList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 pt-1"
            v-if="craft &&craft.smallApertureList &&craft.smallApertureList.length > 0 &&conDeail.smallAperture">
            <div class="row align-items-center">
              <span>最小孔径:</span>
              <div class="col-md">
                <select class="form-control" v-model="conDeail.smallAperture" disabled>
                  <option v-for="item in craft.smallApertureList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 pt-1"
            v-if="craft &&craft.halAholeList &&craft.halAholeList.length > 0 &&conDeail.halAhole">
            <div class="row align-items-center">
              <span>半&emsp;&emsp;孔:</span>
              <div class="col-md">
                <select class="form-control" v-model="conDeail.halAhole" disabled>
                  <option v-for="item in craft.halAholeList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.buryBlindHoleList &&craft.buryBlindHoleList.length > 0 &&conDeail.buryBlindHole">
            <div class="row align-items-center">
              <span>埋&nbsp;&nbsp;盲&nbsp;&nbsp;孔:</span>
              <div class="col-md">
                <select class="form-control" v-model="conDeail.buryBlindHole" disabled>
                  <option v-for="item in craft.buryBlindHoleList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="craft &&craft.resistanceList &&craft.resistanceList.length > 0 &&conDeail.resistance">
            <div class="row align-items-center">
              <span>抗&emsp;&emsp;阻:</span>
              <div class="col-md">
                <select class="form-control" v-model="conDeail.resistance" disabled>
                  <option v-for="item in craft.resistanceList" :key="item.recordId" :value="item.recordId">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="conDeail.throughHole">
            <div class="row align-items-center">
              <span>通孔个数:</span>
              <div class="col-md">
                <input type="text" class="form-control" v-model="conDeail.throughHole" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="conDeail.throughHole">
            <div class="row align-items-center">
              <span>通孔平米:</span>
              <div class="col-md">
                <input type="text" class="form-control" v-model="conDeail.throughHole" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="conDeail.countersinkHole">
            <div class="row align-items-center">
              <span>沉&nbsp;&nbsp;头&nbsp;&nbsp;孔:</span>
              <div class="col-md">
                <input type="text" class="form-control" v-model="conDeail.countersinkHole" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="conDeail.customerFileName">
            <div class="row align-items-center">
              <span>客户文件:</span>
              <div class="col-md">
                <input type="text" class="form-control" v-model="conDeail.customerFileName" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="conDeail.customerMaterialNo">
            <div class="row align-items-center">
              <span>客户物料:</span>
              <div class="col-md">
                <input type="text" class="form-control" v-model="conDeail.customerMaterialNo" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-1" v-if="conDeail.shippingAddress">
            <div class="row align-items-center">
              <span>出货地点:</span>
              <div class="col-md">
                <input type="text" class="form-control" v-model="conDeail.shippingAddress" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-1" v-if="conDeail.specialCraft">
            <div class="row align-items-center">
              <span>特殊工艺:</span>
              <div class="col-md">
                <span class="form-control alert-secondary">
                  {{ conDeail.specialCraftVal }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-1" v-if="conDeail.remark">
            <div class="row align-items-center">
              <span class="p-4">备注:</span>
              <div class="col-md">
                <textarea class="form-control" placeholder="请填入备注" rows="3" v-model="conDeail.remark" disabled></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-3">
            <div class="row pb-2 border-top">
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  数&emsp;&emsp;量:
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="conDeail.quantityCopy" disabled/>
                  </div>
                </div>
              </div>
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  <span>平米:</span>
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="conDeail.orderDeailArea" disabled/>
                  </div>
                </div>
              </div>
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  <span>PCS单价(RMB):</span>
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="pricees.price" disabled/>
                  </div>
                </div>
              </div>
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  平米单价(RMB):
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="pricees.areaPrice" disabled/>
                  </div>
                </div>
              </div>
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  <span>总金额(RMB):</span>
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="conDeail.subTotal" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-3" v-if="pricees.exchangeType">
            <div class="row pb-2 border-top">
              <div class="col-md-12 pt-1">
                <div class="row align-items-center">
                  汇率说明：
                  <div class="col-md">
                    汇率：{{pricees.exchangeRate}}&emsp;
                    时间：{{pricees.exchangeDateStr}}&emsp;
                    外币：{{pricees.exchangeType}}
                  </div>
                </div>
              </div>
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  <span>PCS单价({{ pricees.exchangeType }}):</span>
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="pricees.exPrice" disabled/>
                  </div>
                </div>
              </div>
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  平米单价({{ pricees.exchangeType }}):
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="pricees.exAreaPrice" disabled/>
                  </div>
                </div>
              </div>
              <div class="col-md pt-1">
                <div class="row align-items-center">
                  <span>总金额({{ pricees.exchangeType }}):</span>
                  <div class="col-md">
                    <input type="text" class="form-control" v-model="conDeail.exSubTotal" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["conDeail", "craft", "pricees"]
};
</script>
