<template>
  <div>
    <div class="row border-top pb-3" style="font-size: 0.8rem">
      <template v-for="(row, recordId) in setUpList">
        <div class="col-md-12" :key="recordId">
          <div v-for="item in row.list" :key="item.recordId">
            <div v-if="item.useFlag">
              <div class="row border-bottom align-items-center" style="font-size: 0.5rem;">
                <div class="col-md-4 border-secondary border-right" :title="row.itemName">
                  {{row.name}}:
                  <span v-for="itemRow in item.list" :key="itemRow.recordId">
                      <span v-if="itemRow.type == 1">
                      <span v-if="itemRow.columnCondition == 1">{{itemRow.itemName}}＝{{itemRow.columnValVal}}</span>
                      <span v-if="itemRow.columnCondition == 2">{{itemRow.itemName}}≠{{itemRow.columnValVal}}</span>

                      <span v-if="itemRow.columnCondition == 4">{{itemRow.columnValVal}}＜{{itemRow.itemName}}</span>
                      <span v-if="itemRow.columnCondition == 6">{{itemRow.columnValVal}}≤{{itemRow.itemName}}</span>
                      <span v-if="itemRow.columnConditionTwo == 6">{{itemRow.columnValTwo}}≤</span>
                      <span v-if="itemRow.columnConditionTwo == 4">{{itemRow.columnValTwo}}＜</span>

                      <span v-if="itemRow.columnConditionTwo == 3">＜{{itemRow.columnValTwo}}</span>
                      <span v-if="itemRow.columnConditionTwo == 5">≤{{itemRow.columnValTwo}}</span>
                      <span v-if="itemRow.columnCondition == 3">{{itemRow.itemName}}＜{{itemRow.columnValVal}}</span>
                      <span v-if="itemRow.columnCondition == 5">{{itemRow.itemName}}≤{{itemRow.columnValVal}}</span>
                    </span>
                    <span v-if="itemRow.type == 2">
                      <span v-if="itemRow.columnCondition == 1">{{itemRow.itemName}}＝</span>
                      <span v-if="itemRow.columnCondition == 2">{{itemRow.itemName}}≠</span>
                      <span v-if="itemRow.columnCondition == 3">{{itemRow.itemName}}包含</span>
                      <span v-if="itemRow.columnCondition == 4">{{itemRow.itemName}}不包含</span>{{itemRow.columnValVal}}
                    </span>
                  </span>
                </div>
                <div class="col-md text-right border-secondary border-right p-1">
                  单价:￥ {{item.price}}
                  <span v-if="row.countMethod == 1">元/㎡</span>
                  <span v-if="row.countMethod == 2">元/PCS</span>
                  <span v-if="row.countMethod == 3">元/孔</span>
                  <span v-if="row.countMethod == 4">元</span>
                </div>
                <div class="col-md text-right">金额:￥{{item.countMoney}} 元</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="modal fade" id="moneyCountTable" tabindex="-1" role="dialog" aria-labelledby="moneyCountTable" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row pt-3"><div class="col-md text-center">加工费计算原则如下</div></div>
            <div class="row pt-3 pl-3 pr-3" style="font-size: 0.8rem;">
              <template v-for="(row, recordId) in setUpList">
                <div class="col-md-12" :key="recordId">
                  <div class="row border-bottom align-items-center" style="font-size: 0.5rem;">
                    <div class="col-md-12 p-1">{{row.name}}</div>
                  </div>
                  <div v-for="item in row.list" :key="item.recordId">
                    <div class="row border-bottom align-items-center" style="font-size: 0.5rem;">
                      <div class="col-md-4 p-1" :title="row.itemName" style="background-color: #e9ecef;">
                        <span v-for="itemRow in item.list" :key="itemRow.recordId">
                              <span v-if="itemRow.type == 1">
                                <span v-if="itemRow.columnCondition == 1">{{itemRow.itemName}}＝{{itemRow.columnValVal}}</span>
                                <span v-if="itemRow.columnCondition == 2">{{itemRow.itemName}}≠{{itemRow.columnValVal}}</span>

                                <span v-if="itemRow.columnCondition == 4">{{itemRow.columnValVal}}＜{{itemRow.itemName}}</span>
                                <span v-if="itemRow.columnCondition == 6">{{itemRow.columnValVal}}≤{{itemRow.itemName}}</span>
                                <span v-if="itemRow.columnConditionTwo == 6">{{itemRow.columnValTwo}}≤</span>
                                <span v-if="itemRow.columnConditionTwo == 4">{{itemRow.columnValTwo}}＜</span>

                                <span v-if="itemRow.columnConditionTwo == 3">＜{{itemRow.columnValTwo}}</span>
                                <span v-if="itemRow.columnConditionTwo == 5">≤{{itemRow.columnValTwo}}</span>
                                <span v-if="itemRow.columnCondition == 3">{{itemRow.itemName}}＜{{itemRow.columnValVal}}</span>
                                <span v-if="itemRow.columnCondition == 5">{{itemRow.itemName}}≤{{itemRow.columnValVal}}</span>
                              </span>
                              <span v-if="itemRow.type == 2">
                                <span v-if="itemRow.columnCondition == 1">{{itemRow.itemName}}＝</span>
                                <span v-if="itemRow.columnCondition == 2">{{itemRow.itemName}}≠</span>
                                <span v-if="itemRow.columnCondition == 3">{{itemRow.itemName}}包含</span>
                                <span v-if="itemRow.columnCondition == 4">{{itemRow.itemName}}不包含</span>
                                {{itemRow.columnValVal}}
                              </span>
                            </span>
                      </div>
                      <div class="col-md border-secondary border-right p-1">
                        单价:￥ {{item.price}}
                        <span v-if="row.countMethod == 1">元/㎡</span>
                        <span v-if="row.countMethod == 2">元/PCS</span>
                        <span v-if="row.countMethod == 3">元/孔</span>
                        <span v-if="row.countMethod == 4">元</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["setUpList"]
};
</script>
